<template>
  <div class="pay_status">
    <div class="pay_success">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="选择商品"></el-step>
        <el-step title="确认订单信息"></el-step>
        <el-step title="成功提交订单"></el-step>
      </el-steps>
      <div class="pay_con flex-y align-center">
        <div class="flex align-center">
          <img src="../../assets/image/daizhifuicon.png" alt>
          <div>
            <p class="pay_con_top1">恭喜你，支付成功！</p>
            <p class="pay_con_top2">
              共计支付
              <i>{{order.totalAmount | price()}}</i>元
            </p>
            <p
              class="pay_con_top3"
            >订单编号:{{order.orderSn}} &nbsp;&nbsp;订单金额:{{order.totalAmount | price()}}</p>
          </div>
        </div>
        <p class="pay_con_tip">您的订单已经生成,商家正在为您备货/制作,制作过程中请与商家积极沟通制作事宜!</p>
      </div>
      <p class="pay_success_info3">
        <span @click="orderView">查看订单</span>
        <span @click="$router.push('/')">返回首页</span>
      </p>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  data() {
    return {
      active: 3,
      order: {}
    }
  },
  mounted() {
    const { params } = this.$route
    const { oid } = params
    this.$http.get('/tOrder/findByOrderSn', { params: { orderSn: oid } }).then(res => {
      const { data } = res.data
      this.order = data
    })
  },
  methods: {
    orderView() {
      this.$router.push({ name: 'orderDetail', params: { id: this.order.orderSn } })
    }
  }
}
</script>
<style lang="scss" scoped>
.pay_status {
  padding-top: 30px;
  .pay_con {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin:0 auto;
    // margin: 60px 0 30px 0;
    margin-top: 60px;
    margin-bottom: 40px;
    width: 880px;
    align-items: flex-start;
    // max-width: 1200px;
    // justify-content: center;
    // margin-top: 40px;
    // margin-bottom: 41px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 26px;
    }
    &_top1 {
      font-size: 18px;
      color: #0db168;
    }
    &_top2 {
      font-size: 16px;
      color: #333;
      margin: 10px 0;
      i {
        color: red;
      }
    }
    &_top3 {
      font-size: 16px;
      color: #333;
      i {
        color: #ff9800;
      }
    }
    &_top4 {
      font-size: 16px;
      color: #999999;
      margin-top: 10px;
    }
    &_tip {
      font-size: 14px;
      color: #999999;
      margin-top: 10px;
    }
    .pay_con_tip{
      padding-left: 66px;
    }
  }
  .pay_success {
    width: 1200px;
    margin: 150px auto;
    &_info1 {
      text-align: center;
      font-size: 16px;
      color: #999999;
      margin-bottom: 40px;
    }
    &_info2 {
      text-align: center;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      letter-spacing: 0px;
      color: #333;
      margin-bottom: 58px;
      &_one {
        display: inline-block;
        width: 40px;
        height: 18px;
        background-color: #0db168;
        text-align: center;
        color: #fff;
        line-height: 18px;
        font-size: 12px;
        margin-right: 13px;
      }
      &_two {
        color: #ff0036;
      }
      &_three {
        color: #0db168;
      }
    }
    &_info3 {
      text-align: center;
      margin: 0 47px 470px auto;
      width: 100%;
      max-width: 1200px;
      span {
        display: inline-block;
        width: 140px;
        height: 40px;
        line-height: 40px;
        background-color: #0db168;
        font-size: 15px;
        color: #ffffff;
        margin-right: 47px;
        cursor: pointer;
        &:nth-of-type(2) {
          background: #fff;
          border: 1px solid #0db168;
          color: #0db168;
        }
      }
    }
  }
}
.time {
  color: orange;
}
</style>

